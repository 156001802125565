<template lang="">
  <div>
    <b-modal
      id="modal-cancel-flight-vn1a"
      :title="$t('reservation.cancelFlight')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-75"
      footer-class="px-50 m-0"
      centered
      hide-footer
      no-close-on-backdrop
      size="lg"
      @show="showHandle"
    >
      <IAmOverlay :loading="loading">
        <div v-if="segmentList?.length">
          <div class="text-medium-4 d-flex-between font-weight-bolder">
            <div>{{ $t('reservation.selectItineraryToCancel') }}</div>
            <BButton
              v-b-tooltip.hover
              variant="flat-dark"
              class="rounded-circle p-50"
              title="Tải lại hành trình"
              @click="getSegment"
            >
              <feather-icon
                icon="RotateCwIcon"
                size="16"
                :class="`${loading ? 'spinner-1s' : ''}`"
              />
            </BButton>
          </div>
          <b-form-group>
            <template #label>
              <b-form-checkbox
                v-model="allSelected"
                aria-describedby="flights"
                aria-controls="flights"
                class="custom-checkbox-label mb-50"
                @change="toggleAll"
              >
                <div class="text-body-2">
                  {{ $t('reservation.selectAll') }}
                </div>
              </b-form-checkbox>
            </template>
            <template v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-group-cancel-flight-vn1a"
                v-model="selectedFlightToCancel"
                :aria-describedby="ariaDescribedby"
                stacked
                name="flights"
              >
                <div
                  v-for="(segment) in segmentList"
                  :key="segment.index"
                  class="d-flex "
                >
                  <b-form-checkbox
                    :value="segment"
                    class="custom-checkbox-label mb-50"
                  >
                    <span class="font-weight-bolder font-medium-3 text-dark">{{ segment.segmentText }}</span>
                  </b-form-checkbox>
                </div>
              </b-form-checkbox-group>
            </template>
          </b-form-group>

          <BAlert
            show
            variant="danger"
            class="px-2 py-1 mt-50"
          >
            Hủy chuyến bay sẽ hủy hẹn giờ xuất vé (nếu có)
          </BAlert>
        </div>

        <template v-else-if="responseText">
          <b-form-textarea
            :value="responseText.booking.join('\n')"
            class="font-weight-bolder text-uppercase"
            rows="16"
            max-rows="40"
            style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif; height: 70vh; overflow: auto;"
          />
        </template>

        <div
          v-else
          class="d-flex-center"
        >
          {{ $t('loading') }}
        </div>

        <div class="d-flex-center gap-2 my-50">
          <b-button
            variant="outline-secondary"
            class="center rounded-pill px-1"
            @click="closeHandle()"
          >
            {{ $t('reservation.close') }}
          </b-button>

          <b-button
            v-if="!responseText"
            class="border-0 px-1"
            variant="outline-danger"
            :disabled="!selectedFlightToCancelLength"
            pill
            @click="submitHandle"
          >
            {{ $t('reservation.cancelFlight') }}
          </b-button>
        </div>
      </IAmOverlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BButton, VBTooltip, BFormTextarea,
} from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BButton,
    BFormTextarea,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },

  directives: { 'b-tooltip': VBTooltip },
  props: {
    source: {
      type: String,
      default: '',
    },
    pnrNumber: {
      type: String,
      default: '',
    },
    paxCode: {
      type: String,
      default: '',
    },
    agencyCode: {
      type: String,
      default: '',
    },
  },
  setup(props, { root }) {
    const { commandsGetBooking, commandsCancelSegment, loading } = useReservationHandle()
    const segmentList = ref([])
    const selectedFlightToCancel = ref()
    const allSelected = ref(false)
    const responseText = ref()
    async function getSegment() {
      const payload = {
        source: props.source,
        pnrNumber: props.pnrNumber,
      }
      const res = await commandsGetBooking(payload)
      segmentList.value = res.segmentList
    }
    async function showHandle() {
      selectedFlightToCancel.value = []
      segmentList.value = []
      allSelected.value = false
      responseText.value = ''

      await getSegment()
    }
    watch(selectedFlightToCancel, newValue => {
      if (newValue?.length === 0) {
        allSelected.value = false
      } else if (newValue?.length === segmentList.value.length) {
        allSelected.value = true
      } else {
        allSelected.value = false
      }
    }, { deep: true, immediate: true })

    function toggleAll() {
      selectedFlightToCancel.value = allSelected.value ? segmentList.value.map(item => item) : []
    }

    function closeHandle() {
      root.$bvModal.hide('modal-cancel-flight-vn1a')
    }
    function submitHandle() {
      const contentMsg = () => this.$createElement('div', {
        domProps: {
          innerHTML: `<div class="font-weight-bolder">Bạn có chắc chắn muốn huỷ chuyến bay?</div>
            ${selectedFlightToCancel.value.map(item => `<div class="font-weight-bolder font-medium-4 text-nowrap">${item.segmentText}</div>`).join('')}`,
        },
      })
      const titleMsg = () => this.$createElement('h4', { domProps: { innerHTML: root.$t('reservation.cancelFlight') } })
      root.$bvModal
        .msgBoxConfirm(contentMsg(), {
          title: titleMsg(),
          size: 'md',
          okVariant: 'danger',
          okTitle: root.$t('reservation.confirm'),
          cancelTitle: root.$t('modal.no'),
          cancelVariant: 'flat-dark',
          centered: true,
        })
        .then(async value => {
          if (value) {
            const payload = {
              segmentList: selectedFlightToCancel.value,
              source: props.source,
              agencyCode: props.agencyCode,
              paxCode: props.paxCode,
              pnrNumber: props.pnrNumber,
            }

            responseText.value = await commandsCancelSegment(payload)
            if (responseText.value) segmentList.value = []
          }
        })
    }

    const selectedFlightToCancelLength = computed(() => selectedFlightToCancel.value?.length)
    return {
      showHandle,
      segmentList,
      selectedFlightToCancel,
      loading,
      getSegment,
      allSelected,
      submitHandle,
      toggleAll,
      closeHandle,
      selectedFlightToCancelLength,
      responseText,
      isEmpty,
    }
  },
}
</script>
<style lang="">

</style>
